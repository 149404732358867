import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import { CANCEL_BET, SET_COUPON_DATA } from "../../Redux/types";
import { findFixtureWithOutcomes } from "../../Services/apis";
import {
  addToCoupon,
  placeBet,
  removeSelection,
  updateWinnings,
} from "../../Redux/actions";
import { getEventDetails } from "../../Services/apis";
import { calculateBonus, createID } from "../../Utils/couponHelpers";
import { formatDate, formatNumber } from "../../Utils/helpers";
import ViewFixtureModal from "../../Components/Modal/ViewFixtureModal";

export const Cashdesk = () => {
  const { coupon, removedSelection } = useSelector((state) => state.couponData);
  const { SportsbookGlobalVariable, SportsbookBonusList } = useSelector(
    (state) => state.sportsBook
  );
  const { betPlaced } = useSelector((state) => state.couponData);
  const [selections, setSelections] = useState([
    {
      eventId: "",
      gameId: "",
      eventDate: "",
      eventName: "",
      tournament: "",
      marketCode: "",
      odds: "",
      outcomes: [],
    },
  ]);

  const [showFixture, setShowFixture] = useState(false);
  const [selected, setSelected] = useState(null);

  const dispatch = useDispatch();

  const fetchEvent = async (id, i) => {
    try {
      const res = await getEventDetails(id);
      res && setSelected({ fixture: res, index: i });
      res && setShowFixture(true);
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    if (coupon.selections.length === 0) {
      // setSelections([
      //   {
      //     event_id: "",
      //     event_date: "",
      //     event_name: "",
      //     tournament_name: "",
      //     marketCode: "",
      //     odds: "",
      //     outcomes: [],
      //   },
      // ]);
    } else {
      const newArr = coupon.selections.map((selection) => ({
        eventId: selection.eventId,
        gameId: selection.gameId,
        eventName: selection.eventName,
        eventDate: formatDate(selection.eventDate, "HH:mm"),
        tournament: selection.tournament,
        category: selection.category,
        odds: selection.odds,
      }));
      setSelections(newArr);
    }
    document.getElementById(`event_id_0`).focus();
    // return () => dispatch({ type: CANCEL_BET });
  }, []);

  useEffect(() => {
    if (betPlaced) {
      setSelections([
        {
          eventId: "",
          gameId: "",
          eventDate: "",
          eventName: "",
          tournament: "",
          marketCode: "",
          odds: "",
          outcomes: [],
        },
      ]);
      document.getElementById(`event_id_0`).value = "";
      document.getElementById(`event_code_0`).value = "";
      document.getElementById(`event_id_0`).focus();
    }
  }, [betPlaced]);

  useEffect(() => {
    if (removedSelection) {
      if (removedSelection.type === "click") {
        const newArr = [...selections];
        if (removedSelection.selection) {
          if (newArr.length === 1) {
            resetCashDesk();
          } else {
            const index = newArr.findIndex(
              (item) =>
                parseInt(item.eventId) === removedSelection.selection.eventId
            );
            if (index !== -1) {
              newArr.splice(index, 1);
              setSelections(newArr);
              document.getElementById(`event_id_${index}`).value = "";
              document.getElementById(`event_code_${index}`).value = "";
              document.getElementById(`event_id_${index}`).focus();
            }
          }
        }
      } else if (removedSelection.type === "all") {
        setSelections([
          {
            eventId: "",
            gameId: "",
            eventDate: "",
            eventName: "",
            tournament: "",
            marketCode: "",
            odds: "",
            outcomes: [],
          },
        ]);
        document.getElementById(`event_id_0`).value = "";
        document.getElementById(`event_code_0`).value = "";
        document.getElementById(`event_id_0`).focus();
      }
    }
  }, [removedSelection]);

  const findEvent = (e, i) => {
    const value = e.target.value;
    const newArr = [...selections];
    newArr[i].gameId = value;
    if (value.length >= 4 || e.key === "Enter") {
      findFixtureWithOutcomes(value)
        .then((res) => {
          if (res.gameID == value || res.printID == value) {
            const input = document.getElementById(`event_code_${i}`);
            newArr[i].eventId = res.gameID;
            newArr[i].eventName = res.competitor1 + " - " + res.competitor2;
            newArr[i].eventDate = formatDate(res.date, "HH:mm");
            newArr[i].tournament = res.tournament;
            newArr[i].category = res.categoryName;
           
            newArr[i].fixture = res;
            setSelections(newArr);
            input.focus();
          } else {
            toast.error("Fixture not found");
            e.target.value = "";
          }
        })
        .catch((err) => {
          toast.error("Something went wrong. Please try again");
        });
    }
  };

  const changeCode = (e, i) => {
    const value = e.target.value;

    const newArr = [...selections];
    newArr[i].marketCode = value;
    const fixture = newArr[i].fixture;

    let index = coupon.selections.findIndex(
      (item) => item.eventId === newArr[i].fixture.gameID
    );

    if (value === "") {
     
      if (index !== -1) {
        dispatch(
          removeSelection(coupon.selections[index].element_id, "single")
        );
        newArr[i].odds = null;
        setSelections(newArr);
      }

      return;
    }

    if (fixture.outcomes) {
      const outcomes = newArr[i].fixture.outcomes;
      const outcome = getOutcome(outcomes, value, fixture.is_live);

      if (outcome && outcome.odds !== "0.0") {
        newArr[i].odds = outcome.odds;

        // remove previous selections if exist
        if (index !== -1) {
          dispatch(
            removeSelection(coupon.selections[index].element_id, "single")
          );
        }

        dispatch(
          addToCoupon(
            fixture,
            outcome,
            createID(
              fixture.matchID,
              outcome.marketId,
              outcome.outcomeName,
              outcome.outcomeID
            ),
            fixture.is_live === 0 ? "pre" : "live"
          )
        );
      } else {
        toast.error("Selection not found");
      }
      setSelections(newArr);
    } else {
      toast.error(`No market available for ${fixture.name}`);
      removeCDLine(i);
    }
  };

  const addCDLine = () => {
    setSelections([
      ...selections,
      {
        gameId: "",
        eventId: "",
        eventDate: "",
        eventName: "",
        tournament: "",
        outcomeCode: "",
        odds: "",
        outcomes: [],
      },
    ]);
  };

  const removeCDLine = (i) => {
    const newArr = [...selections];
    // if only one item in selection, reset cash desk
    if (newArr.length === 1) {
      resetCashDesk();
    } else {
      if (newArr[i].gameId) {
        //if game id exist in selection, find selection in coupon
        let index = coupon.selections.findIndex(
          (item) => item.eventId === parseInt(newArr[i].fixture.gameID)
        );
        // if selection exist in coupon, remove selection from coupon
        if (index !== -1) {
          dispatch( 
            removeSelection(coupon.selections[index].element_id, "click")
          );
        } 
        // finaly remove selection and update cash desk
        newArr.splice(i, 1);
        setSelections(newArr);
      } else {//if no game id, remove selection from cash desk
        newArr.splice(i, 1);
        setSelections(newArr);
      }
    }
  };

  const updateSystemWinnings = (stake, action) => {
    let coupondata = { ...coupon };
    coupondata.totalStake = stake;

    if (stake !== "") {
      coupondata.exciseDuty = (coupondata.totalStake * 0) / 100;
      coupondata.stake = coupondata.totalStake - coupondata.exciseDuty;
      coupondata.minStake = parseFloat(stake) / coupondata.noOfCombos;

      //calculate winnings
      let minWinnings =
        parseFloat(coupondata.minOdds) * parseFloat(coupondata.minStake);
      let maxWinnings =
        parseFloat(coupondata.maxOdds) * parseFloat(coupondata.minStake);
      //calculate bonus
      coupondata.minBonus = calculateBonus(
        minWinnings,
        coupondata,
        SportsbookGlobalVariable,
        SportsbookBonusList
      );
      coupondata.maxBonus = calculateBonus(
        maxWinnings,
        coupondata,
        SportsbookGlobalVariable,
        SportsbookBonusList
      );
      coupondata.minGrossWin = parseFloat(coupondata.minBonus) + minWinnings;
      coupondata.minWTH =
        ((coupondata.minGrossWin - coupondata.stake) *
          process.env.REACT_APP_WTH_PERC) /
        100;
      coupondata.minWin = coupondata.minGrossWin - coupondata.minWTH;
      coupondata.grossWin = parseFloat(coupondata.maxBonus) + maxWinnings;
      const wthTax =
        ((coupondata.grossWin - coupondata.stake) *
          process.env.REACT_APP_WTH_PERC) /
        100;
      coupondata.wthTax = wthTax < 1 ? 0 : wthTax;
      coupondata.maxWin = coupondata.grossWin - coupondata.wthTax;
    }
    return dispatch({ type: SET_COUPON_DATA, payload: coupondata });
  };

  const getOutcome = (outcomes, code, type) => {
    let outcome = null;
    
    outcomes.forEach((item) => {
      if (item.codeWA === code) {
        outcome = item;
      }
      return;
    });

    return outcome;
  };

  const updateCode = (outcome) => {
    const i = selected.index;
    // clone selections array
    const newArr = [...selections];
  
    newArr[i].marketCode = outcome.code;
    newArr[i].odds = outcome.odds;

    setSelections(newArr);

    // add new line
    addCDLine();
    // focus on new input field after 100 millieseconds
    setTimeout(() => {
      document.getElementById(`event_id_${i + 1}`).focus();
    }, 100);

    setSelected(null);
  };

  const resetCashDesk = () => {
    setSelections([
      {
        gameID: "",
        eventDate: "",
        eventName: "",
        tournament: "",
        outcomeCode: "",
        odds: "",
        outcomes: [],
      },
    ]);
    document.getElementById(`event_id_0`).value = "";
    document.getElementById(`event_code_0`).value = "";
    document.getElementById(`event_id_0`).focus();
    dispatch({ type: CANCEL_BET });
  };

  return (
    <div className="groupsDivMain">
      <div>
        <table
          className="dgStyle logged-table cashdesk-table"
          cellSpacing="0"
          border="0"
          style={{
            borderWidth: "0px",
            borderStyle: "None",
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr className="dgHdrStyle">
              <th></th>
              <th>Event ID</th>
              <th>Event Date</th>
              <th className="l-table__team">Event</th>
              <th>Smart Code</th>
              <th>Odds</th>
            </tr>
          </thead>
          <tbody>
            {selections.map((selection, i) => (
              <tr key={i}>
                <td onClick={() => removeCDLine(i)}>
                  <div className="add-remove-icon"></div> {i + 1}.
                </td>
                <td>
                  <input
                    autoComplete="off"
                    id={`event_id_${i}`}
                    type="text"
                    defaultValue={selection.eventId}
                    maxLength="5"
                    onKeyUp={(e) => findEvent(e, i)}
                  />
                </td>
                <td>
                  <span id="CDdate_1"> {selection.eventDate}</span>
                </td>
                <td>
                  <span
                    id="CDevent_1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (selection.fixture) {
                        setShowFixture(true);
                        setSelected({ fixture: selection.fixture, index: i });
                      }
                    }}
                  >
                    {selection.eventName}
                  </span>
                  <span style={{ color: "grey" }}>
                    {(selection.category || "") + " - " + selection.tournament}
                  </span>
                </td>
                <td>
                  <input
                    id={`event_code_${i}`}
                    type="text"
                    autoComplete="off"
                    defaultValue={selection.outcomeCode}
                    onChange={(e) => changeCode(e, i)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        addCDLine();
                        setTimeout(() => {
                          document.getElementById(`event_id_${i + 1}`).focus();
                        }, 100);
                      } else if (e.key === "Escape") {
                        removeCDLine(i);
                      }
                    }}
                  />
                  <div className="smart-bet">
                    {/* <div className="red-tooltip" id="CDred_1">
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>Shortcut code is invalid
                                </div> */}
                    <div
                      className="tooltip-info"
                      onClick={() => {
                        if (selection.fixture) {
                          fetchEvent(selection.fixture.matchID, i);
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faTable} />
                      {/* <i className="fa fa-table" aria-hidden="true"></i> */}
                    </div>
                  </div>
                </td>
                <td>
                  {/* <span id="CDodd_1">{selection.odds.toFixed(2)}</span>  */}
                  {/* <span id="CDodd_1">{parseFloat(selection.odds).toFixed(2)}</span>  */}
                  <span id="CDodd_1">{Number(selection.odds).toFixed(2)}</span>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="6">
                <div className="add-remove-icon add" onClick={addCDLine}></div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="cashdesk-content">
        <div className="cashdesk-tabs">
          <div className="row">
            <div className="tab">
              Total selection:{" "}
              <span className="selectionss">{coupon.selections.length}</span>
            </div>
            <div className="tab">
              Min Bonus:{" "}
              <span id="CDminBonus">{formatNumber(coupon.minBonus)}</span>
            </div>
            <div className="tab">
              Min Win: <span id="CDminWin">{formatNumber(coupon.minWin)}</span>
            </div>
            <div className="stake-holder">
              <span>Stake</span>
              <input
                id="cashDeskStake"
                type="text"
                onChange={(e) => {
                  if (coupon.bet_type === "Split") {
                    updateSystemWinnings(e.target.value);
                  } else {
                    dispatch(updateWinnings(e.target.value));
                  }
                }}
                value={coupon.totalStake}
                onKeyUp={(e) => {
                  if (e.key === "Enter") dispatch(placeBet(e, "bet"));
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="tab">
              Total Odds: <span className="maxodd">{coupon.totalOdds}</span>
            </div>
            <div className="tab">
              Max Bonus:{" "}
              <span id="CDmaxBonus">{formatNumber(coupon.maxBonus)}</span>
            </div>
            <div className="tab green">
              Max Win: <span id="CDmaxWin">{formatNumber(coupon.maxWin)}</span>
            </div>
            <div className="buttons">
              <button
                type="button"
                className="cancel"
                onClick={() => resetCashDesk()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="proceed"
                id="placeBet"
                onClick={(e) => dispatch(placeBet(e, "bet"))}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
      {showFixture && (
        <ViewFixtureModal
          fixture={selected?.fixture}
          setShow={() => setShowFixture(false)}
          dispatch={dispatch}
          updateCode={updateCode}
        />
      )}
    </div>
  );
};
