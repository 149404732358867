import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaystackButton } from "react-paystack";
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { toast } from "react-toastify";
import { getGatewayKeys, saveTransaction } from "../../Services/apis";
import "../../Styles/_deposit.scss";
import { bankDetail } from "../../Utils/data";
import { useParams } from "react-router-dom";
import { UPDATE_USER_BALANCE } from "../../Redux/types";
import { formatNumber } from "../../Utils/helpers";

const BankDeposit = ({ history }) => {
  const id = useParams();
  const { user } = useSelector((state) => state.auth);
  const [config, setConfig] = useState({
    tx_ref: new Date().getTime(),
    customer_email: user?.email,
    customer_phone: "",
    customer_name: "",
    amount: 100,
    public_key: "",
    // apiKey: "MK_TEST_SAF7HR5F3F",
    // contractCode: "4934121693",
    production: process.env.NODE_ENV === "production",
  });
  const [paymentSuccess, setPaymentSuccess] = useState("");
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const [inputObject, setObject] = useState({
    amount: 0,
  });

  const handleFlutterPayment = useFlutterwave({
    ...config,
    amount: inputObject?.amount,
  });

  const handleChange = (e) => {
    e.preventDefault();
    setObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setDetails(getItem);
    getGateway(id.id);
  }, []);

  const updateAmount = (value) => {
    if (value === 0) {
      setObject({ ...inputObject, amount: 0 });
      return;
    }
    let currentAmount = inputObject.amount;
    if (currentAmount === "") {
      currentAmount = 0;
    }
    const newAmount = currentAmount + value;
    setObject({ ...inputObject, amount: newAmount });
  };

  const onSuccess = (response) => {
    response.paymentMethod = id?.id;
    response.channel = "shop";
    response.amount = inputObject.amount;
    switch (id?.id) {
      case "rave":
        if (response.status === "successful") {
          response.reference = response.tx_ref;
          setPaymentSuccess(
            `Success!! Your account has been credited with ${formatNumber(
              inputObject.amount
            )}`
          );
          // update user balance
          dispatch({
            type: UPDATE_USER_BALANCE,
            payload: user.available_balance + inputObject.amount,
          });

          saveTransaction(response);
          closePaymentModal();
        }
        break;
      case "paystack":
        if (response.message === "Approved") {
          setPaymentSuccess(
            `Success!! Your account has been credited with ${formatNumber(
              inputObject.amount
            )}`
          );
          // update user balance
          dispatch({
            type: UPDATE_USER_BALANCE,
            payload: user.available_balance + inputObject.amount,
          });

          saveTransaction(response);
        } else {
        }
        break;
      case "monnify":
        if (response.status === "SUCCESS") {
          setPaymentSuccess(
            `Success!! Your account has been credited with ${formatNumber(
              inputObject.amount
            )}`
          );
          // update user balance
          dispatch({
            type: UPDATE_USER_BALANCE,
            payload: user.available_balance + inputObject.amount,
          });
          response.reference = response.transactionReference;

          saveTransaction(response);
        }
        break;
    }
    setConfig({ ...config, amount: "" });
  };

  const verifyPayment = async (response) => {
    if (inputObject.amount > 0) {
      if (response.message === "Approved") {
        setPaymentSuccess(
          `Success!! Your account has been credited with ${formatNumber(
            inputObject.amount
          )}`
        );
        // update user balance
        dispatch({
          type: UPDATE_USER_BALANCE,
          payload: user.available_balance + inputObject.amount,
        });
        response.paymentMethod = "paystack";
        response.channel = "shop";
        response.amount = inputObject.amount;
        setConfig({ ...config, amount: "" });
        saveTransaction(response);
        // dispatch({type: SHOW_MODAL, payload: {show: true, type: 'error', message: 'Your'}})
      } else {
        // dispatch({
        //   type: SHOW_MODAL,
        //   payload: {
        //     show: true,
        //     type: "error",
        //     message: "We were unable to process your request",
        //   },
        // });
      }
    }
  };

  function payWithMonnify() {
    window.MonnifySDK.initialize({
      amount: inputObject.amount,
      currency: "NGN",
      reference: "" + Math.floor(Math.random() * 1000000000 + 1),
      customerEmail: user?.email,
      apiKey: config.PBFPubKey,
      contractCode: config.contractCode,
      paymentDescription: "Gaming Account funding",
      isTestMode: config.production,
      paymentMethods: ["CARD", "ACCOUNT_TRANSFER"],
      onComplete: function (response) {
        //Implement what happens when transaction is completed.
        onSuccess(response);
      },
      onClose: function (data) {
        //Implement what should happen when the modal is closed here
      },
    });
  }

  const getGateway = (gateway) => {
    getGatewayKeys(gateway)
      .then((res) => {
        if (res.success) {
          setConfig({
            ...config,
            public_key: res.pub_key,
            currency: "NGN",
            customer: {
              email: user?.email,
              name: user?.username,
            },
            contractCode:
              gateway === "monnify" ? res.monnify_contract_code : "",
          });
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const getItem = bankDetail.filter((item) => {
    const items = item.slug === id.id;

    return items;
  });

  return (
    <div className="deposit">
      <div className="deposit-step">
        <div className="left">
          <h3 style={{ textTransform: "capitalize" }}>{id.id}</h3>
          <p>Find below-outlined steps on how to fund your wallet.</p>
          <ul>
            <li>1. Put in your intended amount of deposit.</li>
            <li>2. Select your deposit medium.</li>
            <li>3. Click on the "Make payment" button</li>
            <li>4. Put in your Bank account/card details.</li>
            <li>5. Put in your 4 digit pin and one-time password (OTP).</li>
            <li>
              4. Reverify your details to ensure you have provided the right
              details i.e amount etc.
            </li>
            <li>4. Complete your request and get funded instantly.</li>
          </ul>
          <p>
            Transaction will expire in 30mins. If you are unable to complete the
            payment within this duration, kindly re-initiate the deposit.
          </p>
        </div>
        <div className="right">
          <div className="top">
            <div className="right-head">
              <img src={details[0]?.logo} alt="logo" className="" />
            </div>
            <h4>Balance: {user?.balance}</h4>
          </div>
          <p className="pl-1">(Card/bank) Instant Credit</p>
          <div className="flex by-1">
            <p>Email: </p>
            <h5>{user?.email} </h5>
          </div>
          <div>
            <div className="flex">
              <label className="w-2"></label>
              <ul className="flex-list">
                <li onClick={() => updateAmount(1000)}>
                  <span>N</span> <br />
                  +1000
                </li>
                <li onClick={() => updateAmount(5000)}>
                  <span>N</span> <br />
                  +5,000
                </li>
                <li onClick={() => updateAmount(10000)}>
                  <span>N</span> <br />
                  +10,000
                </li>{" "}
                <li onClick={() => updateAmount(25000)}>
                  <span>N</span> <br />
                  +25,000
                </li>{" "}
                <li onClick={() => updateAmount(50000)}>
                  <span>N</span> <br />
                  50,000
                </li>
              </ul>
            </div>
            <div className="flex my-1">
              <label className="w-2">Amount</label>
              <input
                name="amount"
                type="number"
                autoComplete="off"
                step="100"
                maxLength="5"
                min="100"
                max="10000"
                className="deposit-input"
                onChange={handleChange}
                value={inputObject?.amount}
              />
            </div>
            <div className="flex my-1">
              <label className="w-2">Type</label>
              <select name="amount" disabled type="text">
                <option value="Card">Card</option>
              </select>
            </div>
          </div>
          <div className="flex-list jb by-1">
            <div>
              <p>Fees:None </p>
            </div>
            <div>
              <p>Minimum Deposit:N100 </p>
            </div>
          </div>
          <div className="flex-list pl-1">
            <p>Currency: NGN </p>
          </div>
          <div className="flex-list jb by-1">
            <div>
              {/* <button className="btn start">+ Deposit</button> */}
              {id.id === "rave" && (
                <button
                  className="btn start mt20 mb20"
                  // disabled={parseInt(inputObject.amount) === 0}
                  onClick={() => 
                    handleFlutterPayment({
                      callback: (response) => onSuccess(response),
                      onClose: () => {},
                    })
                  }
                >
                  {" "}
                  Make Payment
                </button>
              )}
              {id.id === "monnify" && (
                <>
                  <button
                    className="btn start mt20 mb20"
                    onClick={payWithMonnify}
                    disabled={parseInt(inputObject.amount) === 0}
                  >
                    {" "}
                    Make Payment
                  </button>
                </>
              )}
              {id.id === "paystack" && (
                <PaystackButton
                  amount={inputObject.amount * 100}
                  email={user?.email}
                  publicKey={config.PBFPubKey}
                  onSuccess={(ref) => verifyPayment(ref)}
                  text=" Make Payment"
                  disabled={parseInt(inputObject.amount) === 0}
                  className="btn start mt20 mb20"
                />
              )}
            </div>
            <div>
              <button className="btn red" onClick={() => history.goBack()}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDeposit;
