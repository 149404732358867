import React, { useState } from "react";
import { LOADING, SHOW_MODAL } from "../Redux/types";
import { verifyRequest } from "../Services/apis";
import { toast } from "react-toastify";
import { WithdrawalConfirmModal } from "./Modal/WithdrawalConfirmModal";
import { useSelector } from "react-redux";

export const OnlineWithdrawal = ({ dispatch }) => {
  const [code, setCode] = useState("");

  const { user } = useSelector((state) => state.auth);

  const validateCode = () => {
    if (code.length) {
      dispatch({ type: LOADING });
      verifyRequest({ code, userRole: user?.role })
        .then((res) => {
          dispatch({ type: LOADING });
          if (res.success) {
            setCode("");
            dispatch({
              type: SHOW_MODAL,
              payload: {
                open: true,
                title: "Withdrawal Request",
                component: (
                  <WithdrawalConfirmModal
                    data={res.data}
                    dispatch={dispatch}
                    user={user}
                  />
                ),
              },
            });
          } else {
            alert(res.message);
          }
        })
        .catch((err) => {
          dispatch({ type: LOADING });
          toast.error("Something went wrong");
        });
    }
  };

  return (
    <div className="check-coupon">
      <div className="SXTitle">
        <a>Online Withdrawal</a>
      </div>
      <div className="divCouponCheck">
        <div>
          <div className="CheckCouponMain">
            <div className="CheckCpnTxt">Insert your pin</div>
            <input
              name="withdraw_code"
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              onFocus={(e) => setCode("")}
              onKeyUp={(e) => {
                if (e.key === "Enter") validateCode();
              }}
            />
            <button
              onClick={validateCode}
              //   disabled={disabled ? false : true}
              className="btnCheckCoupon btn-send"
              title="Check"
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
};
