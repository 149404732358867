import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { processTransfer, validateDepositCode } from "../../Services/apis";
import { toast } from "react-toastify";
import { SHOW_MODAL, UPDATE_USER_BALANCE } from "../../Redux/types";
import { formatNumber } from "../../Utils/helpers";

const PlayerDeposit = ({ toggle }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [transaction, setTransaction] = useState(null);

  const doValidate = async (e) => {
    e.preventDefault();
    e.target.value = "VERIFYING...";
    e.target.disable = true;
    setLoading(true);

    validateDepositCode({ code, userRole: user.role })
      .then((res) => {
        e.target.disable = false;
        e.target.value = "SUBMIT";
        setLoading(false);
        if (res.success) {
          setTransaction(res.data);
          // toast.success("Transaction found");
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Internal server error!");
        setLoading(false);
      });
  };

  const doTransfer = async (e) => {
    e.target.value = "LOADING...";
    e.target.disable = true;
    setLoading(true);
    if (!loading) {
      processTransfer({ id: transaction.id, role: user?.role })
        .then((res) => {
          e.target.disable = false;
          e.target.value = "Transfer";
          setLoading(false);
          if (res.success) {
            dispatch({
              type: UPDATE_USER_BALANCE,
              payload: res.data.balance,
            });
            toast.success(res.message);
            dispatch({
              type: SHOW_MODAL,
              payload: {
                open: false,
              },
            });
          } else {
            toast.eroor(res.message);
          }
        })
        .catch((err) => {
          toast.error("Internal server error!");
          setLoading(false);
          e.target.disable = false;
          e.target.value = "Transfer";
        });
    }
  };

  return (
    <div className="expense-form">
      <div className="expense-input">
        <label>DEPOSIT CODE</label>
        <input
          type="text"
          onChange={(e) => setCode(e.target.value)}
          name="amount"
          placeholder="Enter deposit code from customer"
        />
      </div>
      {!transaction ? (
        <div className="expense-btn">
          <input
            type="button"
            name="expenses"
            value="SUBMIT"
            className="btn-green"
            onClick={(e) => doValidate(e)}
          />
          <input
            type="button"
            name="expenses"
            value="CANCEL"
            className="btn-red"
            onClick={() =>
              dispatch({
                type: SHOW_MODAL,
                payload: {
                  open: false,
                },
              })
            }
          />
        </div>
      ) : (
        <div className="account form p15" id="change-password">
          <table width="100%" cellSpacing="0" className="tblPP">
            <tbody>
              <tr>
                <td className="cellaSx" width="100%">
                  To User
                </td>
                <td className="cellaSx">:</td>
                <td className="cellaDx">{transaction.username}</td>
                <td className="rfv"></td>
              </tr>
              <tr>
                <td className="cellaSx">Amount</td>
                <td className="cellaSx">
                  <b>:</b>
                </td>
                <td className="cellaDx">{formatNumber(transaction.amount)}</td>
                <td className="rfv"></td>
              </tr>
              <tr>
                <td className="cellaSx">Available Balance</td>
                <td className="cellaSx">
                  <b>:</b>
                </td>
                <td className="cellaDx">
                  {formatNumber(user?.availableBalance)}
                </td>
                <td className="rfv"></td>
              </tr>
              <tr>
                <td colSpan="4">
                  <div className="spacer5" />
                </td>
              </tr>
              <tr>
                <td align="right" colSpan="3">
                  <input
                    type="button"
                    name="s$w$PC$PC$btnResetPwd"
                    value="Cancel"
                    onClick={() => {
                      setTransaction(null);
                      dispatch({
                        type: SHOW_MODAL,
                        payload: {
                          open: false,
                        },
                      });
                    }}
                    className="button"
                  />
                  <input
                    type="button"
                    disabled={loading}
                    onClick={(e) => doTransfer(e)}
                    className="button btn-green"
                    value="Transfer"
                  />
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PlayerDeposit;
