import React, { useState, useEffect, useCallback } from "react";
import { formatDate, formatNumber } from "../Utils/helpers";
import { getBalanceOverview, getTransactions } from "../Services/apis";

export const HomeComponent = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const clientId = process.env.REACT_APP_CLIENT_ID;
  
  useEffect(() => {
    const resp = () => {
      getTransactions({clientId}, 1, 10).then(res => {
        if (res.success && res.data) {setTransactions(res.data)}
      }).catch(err=> console.log(err.message));
    }

    resp();

  }, []);

  const getBalance = () => {
    getBalanceOverview()
      .then((res) => {
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // getBalance();
  }, []);

  
  return (
    <div id="MainContent">
      <div className="RiquadroHome">
        <div className="Cnt">
          <div>
            <div className="RiquadroHomeUsr">
              <div className="Cnt">
                <div>
                  <table
                    width="100%"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <table cellpadding="'0" cellspacing="0" width="100%">
                            <tbody>
                              <tr>
                                <td className="welcomeUser">
                                  <span id="hl_w_PC_PC_lblBentornato">
                                    Welcome
                                  </span>
                                  &nbsp;
                                  <b>
                                    <span
                                      id="hl_w_PC_PC_lblUtente"
                                      className="userLabel"
                                    >
                                      {user?.username}
                                    </span>
                                  </b>
                                </td>
                                <td className="pulsantiLogged">
                                  <span>
                                    <a
                                      id="A1"
                                      className="divAccessLogs"
                                      href="/Account/AccessLogs.aspx"
                                    >
                                      <img
                                        src="img/login.png"
                                        border="0"
                                        alt="Logins report"
                                      />
                                    </a>
                                  </span>
                                  <span id="divDatiUtente">
                                    <a id="A2" href="../Account/User.aspx">
                                      <img
                                        src="img/config.png"
                                        border="0"
                                        alt="User Details"
                                      />
                                    </a>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {user?.role === "Shop" && (
                            <div id="divAgente" className="divTblAgente">
                              <div id="hl_w_PC_PC_divRiepilogoAgente">
                                <div>
                                  <table
                                    className="dgDefaultLoggedStyle"
                                    cellspacing="0"
                                    border="0"
                                    style={{ borderCollapse: "collapse" }}
                                  >
                                    <tbody>
                                      <tr className="dgHdrDefaultLoggedStyle">
                                        <th scope="col">&nbsp;</th>
                                        <th align="center" scope="col">
                                          Availability
                                        </th>
                                        <th align="center" scope="col">
                                          Credit
                                        </th>
                                        <th align="center" scope="col">
                                          Balance
                                        </th>
                                      </tr>
                                      <tr className="dgFirstItemStyle">
                                        <td>Account</td>
                                        <td align="right">
                                          {formatNumber(
                                            user?.availableBalance || 0
                                          )}
                                        </td>
                                        <td align="right">
                                          {formatNumber(
                                            user?.trustBalance || "0.00"
                                          )}
                                        </td>
                                        <td align="right">
                                          {formatNumber(
                                            user?.availableBalance || 0
                                          )}
                                        </td>
                                      </tr>
                                      <tr className="LastTransAItem font-0">
                                        <td>User</td>
                                        <td align="right">
                                          {formatNumber(
                                            data?.user?.balance || "0.00"
                                          )}
                                        </td>
                                        <td align="right">
                                          {formatNumber(
                                            data?.user?.credit || "0.00"
                                          )}
                                        </td>
                                        <td align="right">
                                          {formatNumber(
                                            data?.user?.availablility -
                                              data?.user?.credit || "0.00"
                                          )}
                                        </td>
                                      </tr>
                                      <tr className="dgFirstItemStyle">
                                        <td>Network</td>
                                        <td align="right">
                                          {formatNumber(
                                            data?.network?.availablility ||
                                              "0.00"
                                          )}
                                        </td>
                                        <td align="right">
                                          {formatNumber(
                                            data?.network?.credit || "0.00"
                                          )}
                                        </td>
                                        <td align="right">
                                          {formatNumber(
                                            data?.network?.availablility -
                                              data?.network?.credit || "0.00"
                                          )}
                                        </td>
                                      </tr>
                                      <tr className="LastTransAItem  total">
                                        <td>Total</td>
                                        <td align="right">
                                          {formatNumber(
                                            data?.network?.availablility +
                                              user?.available_balance +
                                              data?.user?.availablility
                                          )}
                                        </td>
                                        <td align="right">
                                          {formatNumber(
                                            data?.network?.credit +
                                              data?.user?.credit || "0.00"
                                          )}
                                        </td>
                                        <td align="right">
                                          {formatNumber(
                                            data?.network?.availablility +
                                              user?.available_balance +
                                              data?.user?.availablility
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <table cellpadding="0" cellspacing="0" width="100%">
              <tbody>
                <tr>
                  <td>
                    <div className="CassaStats">
                      <div className="CassaStatsTitle">CASHIER</div>
                      <table cellpadding="0" cellspacing="0" width="100%">
                        <tbody>
                          <tr className="CassaStatsItem">
                            <td>SportsBetting</td>
                            <td align="right">
                              {formatNumber(user?.availableBalance || 0)}
                            </td>
                          </tr>
                          <tr className="CassaStatsTotals">
                            <td>Total Balance</td>
                            <td align="right">
                              {formatNumber(user?.availableBalance || 0)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div className="LastTrans">
                      <div className="LastTransTitle">
                        <a
                          title="See all transactions"
                          href="../Account/TransactionList.aspx"
                        >
                          Last 10 Transactions
                        </a>
                      </div>
                      <div className="LastTransDivCnt">
                        <div>
                          <table
                            cellspacing="0"
                            cellpadding="0"
                            border="0"
                            style={{
                              borderWidth: "0px",
                              width: "100%",
                              borderCollapse: "collapse",
                            }}
                          >
                            <tbody>
                              {transactions?.map((transaction, t) => (
                                <tr
                                  className={`${
                                    t % 2 === 0
                                      ? "LastTransAItem"
                                      : "LastTransItem"
                                  }`}
                                  key={transaction.id}
                                >
                                  <td>
                                    <a href="#">{transaction.referenceNo}</a>
                                  </td>
                                  <td className="TemplateFieldOneColumn">
                                    <span>
                                      {formatDate(
                                        transaction.transactionDate,
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </span>
                                  </td>
                                  <td>{transaction.description}</td>
                                  <td align="right">
                                    {`${
                                      transaction.type === "debit"
                                        ? "-"
                                        : ""
                                    }` + formatNumber(transaction.amount)}
                                  </td>
                                  <td align="right">
                                    {formatNumber(transaction.balance)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
