import React, { useEffect, useState } from "react";
import { getFixtures } from "../Services/apis";
import { LOADING } from "../Redux/types";
import { useDispatch, useSelector } from "react-redux";
import { removeTournament } from "../Redux/actions";
import FixturesList from "./FixturesList";
import { groupFixtures } from "../Utils/helpers";

export default function Tablet({ tournament, index, history, period }) {
  const [activeMarket, setActiveMarket] = useState(tournament?.markets[0]);
  const [markets, setMarkets] = useState(
    tournament?.markets.sort((a, b) => a.groupID - b.groupID)
  );
  const [selectedMarkets, setSelectedMarkets] = useState(
    tournament?.selectedMarkets
  );
  const [firstMount, setFirstMount] = useState(true);

  const [selectedGroups, setSelectedGroups] = useState("1");
  const [predictions, setPredictions] = useState(tournament?.predictions);
  const [fixtures, setFixtures] = useState([]);
  const [groups, setGroups] = useState([]);
  const dispatch = useDispatch();
  const coupon = useSelector(({ couponData }) => couponData.coupon);

  useState(() => {
    setFixtures(groupFixtures(tournament.fixtures));
    const outcomes = tournament?.predictions?.sort((a, b) => {
      return a.outcomeID - b.outcomeID;
    });
    setPredictions(outcomes);
  }, [tournament]);

  const changeMarket = (market) => {
    dispatch({ type: LOADING });
    setActiveMarket(market);

    getFixtures(
      tournament.sport_tournament_id,
      tournament.sport_id,
      period,
      market.marketID,
      market.specifier
    )
      .then((res) => {
        dispatch({ type: LOADING });
        if (res) {
          const outcomes = res?.outcomeTypes?.sort((a, b) => {
            return a.outcomeID - b.outcomeID;
          });
          setGroups(res.groups);
          setPredictions(outcomes);
          setFixtures(groupFixtures(res.fixtures));
          setSelectedMarkets(
            res.selectedMarket.filter(
              (selectedMarket) => selectedMarket.marketID === market.marketID
            )
          );
          document
            .getElementById(`event-wrapper-${tournament.sport_tournament_id}`)
            .scrollIntoView();
        }
      })
      .catch((err) => {
        dispatch({ type: LOADING });
      });
  };
  useEffect(() => {
    setFirstMount(false);
  }, [firstMount]);
  useState(() => {
    const filteredMarket = markets.find((market) => market.marketID === "1");
    if (!firstMount) {
      changeMarket(filteredMarket);
    }
  }, [markets, selectedGroups]);

  return (
    <div className="oddsViewPanel ">
      <div
        className={`divOdds ${
          predictions?.length <= 9 ? "r1 c" + predictions?.length : "r5 c6"
        }`}
      >
        <div className="title">
          <span>
            <span>{tournament?.sport_category_name}</span>
            <span>{tournament?.sport_tournament_name}</span>
          </span>
          <div className="btns">
            <a
              className="lnkOddsCls"
              onClick={() => dispatch(removeTournament(index))}
            />
            <a className="lnkOddsPrn" />
            <a className="lnkOddsRfh" />
            <a className="lnkOddsBack" />
          </div>
        </div>
        <div className="OddsBreadbrum">
          <ul>
            <li className="sportItem">
              <a title="Soccer">{tournament?.sport_name} </a>
            </li>
            <li className="groupItem">
              <a>{tournament?.sport_category_name}</a>
            </li>
            <li className="eventItem">
              <a>{tournament?.sport_tournament_name}</a>
            </li>
          </ul>
        </div>

        {/* Displaying Groups */}
        <div
          className="tablet-groups"
          style={{
            display: "flex",
            gap: "2px",
            flexWrap: "wrap",
            padding: "4px 12px",
            borderBottom: "8px solid #d1d1d1",
          }}
        >
          {groups?.map((group, i) => (
            <div
              style={{
                display: "flex",
                padding: "8px 16px",
                justifyContent: "center",
                alignItems: "center",
                background:
                  selectedGroups === group.groupID ? "#1b5d06" : "#d1d1d1",
                color: "white",
                fontWeight: "700",
                cursor: "pointer",
                marginBottom: "4px",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
              }}
              key={`group-${i}-${group.marketID}`}
              className={`${
                selectedGroups === group.groupID
                  ? "GroupsDetailsMenuSelected"
                  : "GroupDetailsMenuItem"
              } single `}
            >
              <a
                style={{
                  color: selectedGroups === group.groupID ? "white" : "black",
                }}
                href="#"
                onClick={() => setSelectedGroups(group.groupID)}
                title={group.groupName}
              >
                {group.groupName}
              </a>
            </div>
          ))}
        </div>

        {/* Displaying Markets based on selected group */}
        <div className="divBtn">
          {markets
            ?.filter((market) => market.groupID == selectedGroups)
            .map((market, i) => (
              <div
                key={`market-${i}-${market.marketID}`}
                className={`${
                  activeMarket.marketID === market.marketID
                    ? "OddsDetailsMenuSelected"
                    : "OddsDetailsMenuItem"
                } single `}
              >
                <a
                  href="#"
                  onClick={() => changeMarket(market)}
                  title={market.marketName}
                >
                  {market.marketName}
                </a>
              </div>
            ))}
        </div>
        <div className="clear"></div>
      </div>
      <div
        className="divQt"
        id={`event-wrapper-${tournament?.sport_tournament_id}`}
      >
        <FixturesList
          fixtures={fixtures}
          predictions={predictions}
          selectedMarkets={selectedMarkets}
          coupon={coupon}
          dispatch={dispatch}
          history={history}
        />
      </div>
    </div>
  );
}
